import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { RouterOutlet } from '@angular/router';
// import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from "rxjs";
import { TimeService } from './services/time.service';
import { NgIf } from '@angular/common';
import { VersionService } from './services/version.service';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet,NgIf],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppComponent {
  title = 'growing-minds-static-web-app2';

  updateApp:boolean = false;
  
  appOnline = true;
  

  constructor(
    // private swUpdate: SwUpdate,
    private changeDetectorRef: ChangeDetectorRef,
    // private timeService: TimeService,
    private versionService: VersionService
  ) {}

  @HostListener('document:visibilitychange', ['$event'])
  @HostListener('window:focus', ['event'])
  async visibilitychange() {
    this.versionService.checkVersion();
  }
  @HostListener("window:online", ["$event"])
  async online() {
    this.appOnline = true;
    this.changeDetectorRef.detectChanges();
  }
  @HostListener("window:offline", ["$event"])
  async offline() {
    this.appOnline = false;
    this.changeDetectorRef.detectChanges();
  }




  ngOnInit() {

    // Didnt use hostlistener because I could not pass options successfully 
      //  This is to disable the swipe navigating feature
      
      const options= { passive: false };
    document.addEventListener('touchstart', function (event,) {

      if (event.touches.length === 1) {
        const touch = event.touches[0];
         // only need to check for swipe left only - ONLY CHILD
        if (touch.pageX < 20 && window.location.href.includes('child')) {
          event.preventDefault();
        }
       

      } 
    }, options);


    this.onInit();



  }

  async onInit() {
    // this.swUpdate.versionUpdates
    //   .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
    //   .subscribe(async (evt) => {
    //     this.updateApp = true;
    //     this.changeDetectorRef.detectChanges();
    //     await this.timeService.wait(3000, Date.now());
    //     document.location.reload();
    //   });
  }


  }


